import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Show,
    Text,
    VStack,
    useMediaQuery,
} from '@chakra-ui/react'
import { Localization } from '@core/app/types/globalApiType'
import {
    FilterSettings,
    PostsFilterArea,
    PostsFilterItems,
    PostsFilters,
    PostsMetadata,
    PostsFilterCities,
} from '@core/app/types/postsApiType'
import { IconUndo } from '@core/assets/icons/undo'
import { AppButton } from '@core/components/AppButton'
import { CustomModal } from '@core/components/Modal/Modal'
import { CustomFullScreenModal } from '@core/components/Modal/ModalFullScreen'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { CheckboxItem } from '@page/posts/components/Checkbox/Checkbox'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    meta: PostsMetadata | undefined
    filters: PostsFilters | undefined
    settings: FilterSettings | undefined
    onSelect: (id: number | string, type: string, value: boolean) => void
    reset: () => void
    isOpened: boolean
    closeModal: () => void
}

export const Sidebar = ({ meta, filters, onSelect, reset, settings, isOpened, closeModal }: Props): JSX.Element => {
    const { t } = useTranslation()
    const { appTheme } = useAppTheme()
    const [isDesktop] = useMediaQuery('(min-width: 1230px)', {
        fallback: false,
    })
    const [localization, setLocalization] = useState<Localization>({})
    const { newSettings } = appTheme || {newSettings: {general: {}}}
    const { general } = newSettings
    const { BackgroundText } = general
    const activeFilters = localStorage.getItem('jobs_search_string')

    useEffect(() => {
        if (!appTheme) return
        if (appTheme && appTheme?.localization) {
            setLocalization(appTheme?.localization)
        }
    }, [appTheme])

    const areas = Object.values(filters?.area || {})
    const isSingleArea = areas.length === 1;

    const sidebarContent = () => (
        <Box w={{ base: '100%', xl: '384px' }} position={{ xl: 'sticky' }} top="120px">
            {isDesktop && (
                <Text
                    mb={5}
                    fontSize={20}
                    fontWeight={600}
                    textTransform="capitalize"
                    height="40px"
                    color={settings?.boxStyle ? 'text.dark' : `#${BackgroundText ? BackgroundText : '000000'}`}
                >
                    {localization[12] ? localization[12] : t('277')}
                </Text>
            )}
            <Box
                w={'100%'}
                bg={settings?.boxStyle ? 'bg.1' : '#fff'}
                borderRadius="8px"
                overflow="hidden"
                position="relative"
                // pb={{ base: 12, xl: 0 }}
                boxShadow={'0px 1px 6px 0px rgba(0,0,0,0.15)'}
                maxHeight="80vh"
                pb={{ base: '50px', xl: '0px' }}
                overflowY="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '-ms-overflow-style': 'none', // Internet Explorer 10+
                    'scrollbar-width': 'none', // Firefox
                }}
            >
                <Flex justifyContent="space-between" alignItems="center" p={{ base: 4, xl: 6 }}>
                    <Text
                        fontSize={{ base: 'sm', xl: 'md' }}
                        fontWeight={{ base: 'medium', xl: 'semibold' }}
                        color={settings?.boxStyle ? 'text.dark' : `#${BackgroundText ? BackgroundText : '000000'}`}
                    >
                        {t('6448')}
                    </Text>
                    <Button
                        h="28px"
                        p="4px 8px"
                        borderRadius="24px"
                        variant="default"
                        backgroundColor="transparent"
                        aria-label={`${t('6475')}`}
                        onClick={() => reset()}
                        isDisabled={!activeFilters}
                        _hover={{
                            backgroundColor: '#F5F5F5',
                            '& svg path': {
                                fill: '#1A1A1A',
                            },
                            '& p': {
                                color: '#1A1A1A',
                            },
                        }}
                        _disabled={{
                            backgroundColor: 'transparent',
                            cursor: 'not-allowed',
                            '& svg path': {
                                fill: '#E0E0E0',
                            },
                            '& p': {
                                color: '#E0E0E0',
                            },
                        }}
                    >
                        <HStack>
                            <Text
                                fontSize="sm"
                                fontWeight="medium"
                                color={
                                    settings?.boxStyle ? '#828282' : `#${BackgroundText ? BackgroundText : '828282'}`
                                }
                            >
                                {t('297')}
                            </Text>
                            <IconUndo
                                w="14"
                                h="14"
                                fill={settings?.boxStyle ? '#828282' : `#${BackgroundText ? BackgroundText : '828282'}`}
                            />
                        </HStack>
                    </Button>
                </Flex>
                <Divider />
                <VStack p={{ base: 4, xl: 6 }} gap={6}>
                    <Box as="div" w="full">
                        <Accordion defaultIndex={[0, 1, 2, 3]} allowMultiple>
                            {settings?.area && (
                                <AccordionItem border="none">
                                    <AccordionButton px="0" _hover={{ bg: 'transparent' }}>
                                        <Box as="span" flex="1" textAlign="left">
                                            <Text
                                                color={
                                                    settings?.boxStyle
                                                        ? 'text.main'
                                                        : `#${BackgroundText ? BackgroundText : '1A1A1A'}`
                                                }
                                                fontWeight="medium"
                                            >
                                                {localization[13] ? localization[13] : t('2414')}
                                            </Text>
                                        </Box>
                                        <AccordionIcon _hover={{ color: 'blue.500' }} />
                                    </AccordionButton>
                                    <AccordionPanel pb={4} px={0}>
                                        <Flex flexDirection="column" gap={4} alignItems="flex-start">
                                            {filters?.area
                                                ? Object.values(filters?.area || {}).map((area: PostsFilterArea[0]) => (
                                                      <Box as="div" key={area.id} w="full">
                                                          <HStack w="full" justifyContent="space-between">
                                                              <CheckboxItem
                                                                  id={`country-${area.id}`}
                                                                  name={area.name}
                                                                  selected={area.selected}
                                                                  onSelect={onSelect}
                                                                  type="country"
                                                              />
                                                              <Text
                                                                  fontSize="xs"
                                                                  fontWeight="medium"
                                                                  color={
                                                                      settings?.boxStyle
                                                                          ? 'text.thirdly'
                                                                          : `#${BackgroundText ? BackgroundText : '747474'}`
                                                                  }
                                                                  pr={1.5}
                                                              >
                                                                  {area.hitCount}
                                                              </Text>
                                                          </HStack>
                                                          {(area.selected || isSingleArea ) && (
                                                              <Box as="div" pl={6} mt={4}>
                                                                  <Flex
                                                                      flexDirection="column"
                                                                      gap={4}
                                                                      alignItems="flex-start"
                                                                  >
                                                                      {Object.values(area.regions || {}).map(
                                                                          (region: PostsFilterItems[0]) => (
                                                                              <Box key={region.id} w="full">
                                                                                  <HStack
                                                                                      w="full"
                                                                                      justifyContent="space-between"
                                                                                  >
                                                                                      <CheckboxItem
                                                                                          id={`region-${region.id}`}
                                                                                          name={region.name}
                                                                                          selected={region.selected}
                                                                                          onSelect={onSelect }
                                                                                          type="region"
                                                                                      />
                                                                                      <Text
                                                                                          fontSize="xs"
                                                                                          fontWeight="medium"
                                                                                          color={
                                                                                              settings?.boxStyle
                                                                                                  ? 'text.thirdly'
                                                                                                  : `#${BackgroundText ? BackgroundText : '747474'}`
                                                                                          }
                                                                                          pr={1.5}
                                                                                      >
                                                                                          {region.hitCount}
                                                                                      </Text>
                                                                                  </HStack>
                                                                                  {region.selected && (
                                                                                      <Box as="div" pl={6} mt={4}>
                                                                                          <Flex
                                                                                              flexDirection="column"
                                                                                              gap={4}
                                                                                              alignItems="flex-start"
                                                                                          >
                                                                                              {Object.values(
                                                                                                  region.cities || {}
                                                                                              ).map(
                                                                                                  (
                                                                                                      city: PostsFilterCities[0]
                                                                                                  ) => (
                                                                                                      <HStack
                                                                                                          w="full"
                                                                                                          key={city.id}
                                                                                                          justifyContent="space-between"
                                                                                                      >
                                                                                                          <CheckboxItem
                                                                                                              id={`city-${city.id}`}
                                                                                                              name={
                                                                                                                  city.name
                                                                                                              }
                                                                                                              selected={
                                                                                                                  city.selected
                                                                                                              }
                                                                                                              onSelect={onSelect}
                                                                                                              type="city"
                                                                                                          />
                                                                                                          <Text
                                                                                                              fontSize="xs"
                                                                                                              fontWeight="medium"
                                                                                                              color={
                                                                                                                  settings?.boxStyle
                                                                                                                      ? 'text.thirdly'
                                                                                                                      : `#${BackgroundText ? BackgroundText : '747474'}`
                                                                                                              }
                                                                                                              pr={1.5}
                                                                                                          >
                                                                                                              {
                                                                                                                  city.hitCount
                                                                                                              }
                                                                                                          </Text>
                                                                                                      </HStack>
                                                                                                  )
                                                                                              )}
                                                                                          </Flex>
                                                                                      </Box>
                                                                                  )}
                                                                              </Box>
                                                                          )
                                                                      )}
                                                                  </Flex>
                                                              </Box>
                                                          )}
                                                      </Box>
                                                  ))
                                                : null}
                                        </Flex>
                                    </AccordionPanel>
                                </AccordionItem>
                            )}
                            {settings?.industry && (
                                <AccordionItem border="none">
                                    <AccordionButton px="0" _hover={{ bg: 'transparent' }}>
                                        <Box as="span" flex="1" textAlign="left">
                                            <Text
                                                color={
                                                    settings?.boxStyle
                                                        ? 'text.main'
                                                        : `#${BackgroundText ? BackgroundText : '1A1A1A'}`
                                                }
                                                fontWeight="medium"
                                            >
                                                {localization[14] ? localization[14] : t('189')}
                                            </Text>
                                        </Box>
                                        <AccordionIcon _hover={{ color: 'blue.500' }} />
                                    </AccordionButton>
                                    <AccordionPanel pb={4} px={0}>
                                        <Flex flexDirection="column" gap={4} alignItems="flex-start">
                                            {filters?.industry
                                                ? Object.values(filters.industry || {}).map(
                                                      (industry: PostsFilterItems[0]) => (
                                                          <HStack
                                                              w="full"
                                                              justifyContent="space-between"
                                                              key={industry.id}
                                                          >
                                                              <CheckboxItem
                                                                  id={`industry-${industry.id}`}
                                                                  name={industry.name}
                                                                  selected={industry.selected}
                                                                  onSelect={onSelect}
                                                                  type="industry"
                                                              />
                                                              <Text
                                                                  fontSize="xs"
                                                                  fontWeight="medium"
                                                                  color={
                                                                      settings?.boxStyle
                                                                          ? 'text.thirdly'
                                                                          : `#${BackgroundText ? BackgroundText : '747474'}`
                                                                  }
                                                                  pr={1.5}
                                                              >
                                                                  {industry.hitCount}
                                                              </Text>
                                                          </HStack>
                                                      )
                                                  )
                                                : null}
                                        </Flex>
                                    </AccordionPanel>
                                </AccordionItem>
                            )}
                            {settings?.jobType && (
                                <AccordionItem border="none">
                                    <AccordionButton px="0" _hover={{ bg: 'transparent' }}>
                                        <Box as="span" flex="1" textAlign="left">
                                            <Text
                                                color={
                                                    settings?.boxStyle
                                                        ? 'text.main'
                                                        : `#${BackgroundText ? BackgroundText : '1A1A1A'}`
                                                }
                                                fontWeight="medium"
                                            >
                                                {localization[15] ? localization[15] : t('331')}
                                            </Text>
                                        </Box>
                                        <AccordionIcon _hover={{ color: 'blue.500' }} />
                                    </AccordionButton>
                                    <AccordionPanel pb={4} px={0}>
                                        <Flex flexDirection="column" gap={4} alignItems="flex-start">
                                            {filters?.jobType
                                                ? Object.values(filters.jobType || {}).map(
                                                      (jobType: PostsFilterItems[0]) => (
                                                          <HStack
                                                              w="full"
                                                              justifyContent="space-between"
                                                              key={jobType.id}
                                                          >
                                                              <CheckboxItem
                                                                  id={`jobType-${jobType.id}`}
                                                                  name={jobType.name}
                                                                  selected={jobType.selected}
                                                                  onSelect={onSelect}
                                                                  type="jobType"
                                                              />
                                                              <Text
                                                                  fontSize="xs"
                                                                  fontWeight="medium"
                                                                  color={
                                                                      settings?.boxStyle
                                                                          ? 'text.thirdly'
                                                                          : `#${BackgroundText ? BackgroundText : '747474'}`
                                                                  }
                                                                  pr={1.5}
                                                              >
                                                                  {jobType.hitCount}
                                                              </Text>
                                                          </HStack>
                                                      )
                                                  )
                                                : null}
                                        </Flex>
                                    </AccordionPanel>
                                </AccordionItem>
                            )}
                            {settings?.office && (
                                <AccordionItem border="none">
                                    <AccordionButton px="0" _hover={{ bg: 'transparent' }}>
                                        <Box as="span" flex="1" textAlign="left">
                                            <Text
                                                color={
                                                    settings?.boxStyle
                                                        ? 'text.main'
                                                        : `#${BackgroundText ? BackgroundText : '1A1A1A'}`
                                                }
                                                fontWeight="medium"
                                            >
                                                {localization[16] ? localization[16] : t('2415')}
                                            </Text>
                                        </Box>
                                        <AccordionIcon _hover={{ color: 'blue.500' }} />
                                    </AccordionButton>
                                    <AccordionPanel pb={4} px={0}>
                                        <Flex flexDirection="column" gap={4} alignItems="flex-start">
                                            {filters?.office
                                                ? Object.values(filters.office || {}).map(
                                                      (office: PostsFilterItems[0]) => (
                                                          <HStack
                                                              w="full"
                                                              justifyContent="space-between"
                                                              key={office.id}
                                                          >
                                                              <CheckboxItem
                                                                  id={`office-${office.id}`}
                                                                  name={office.name}
                                                                  selected={office.selected}
                                                                  onSelect={onSelect}
                                                                  type="office"
                                                              />
                                                              <Text
                                                                  fontSize="xs"
                                                                  fontWeight="medium"
                                                                  color={
                                                                      settings?.boxStyle
                                                                          ? 'text.thirdly'
                                                                          : `#${BackgroundText ? BackgroundText : '747474'}`
                                                                  }
                                                                  pr={1.5}
                                                              >
                                                                  {office.hitCount}
                                                              </Text>
                                                          </HStack>
                                                      )
                                                  )
                                                : null}
                                        </Flex>
                                    </AccordionPanel>
                                </AccordionItem>
                            )}
                        </Accordion>
                    </Box>
                    <Show below="xl">
                        <Box
                            position="fixed"
                            bottom={0}
                            left={0}
                            right={0}
                            px={4}
                            py={2}
                            bg="bg.1"
                            w="full"
                            borderTop="1px solid"
                            borderColor="border.1"
                        >
                            <Flex w="full" justifyContent="space-between">
                                <AppButton
                                    translate="no"
                                    title={`${meta?.filteredCount} ${t('3925')}`}
                                    variant="primary"
                                    w="167px"
                                    onClick={() => closeModal()}
                                />
                                <Button variant="secondary" w="167px" onClick={() => closeModal()}>
                                    {t('416')}
                                </Button>
                            </Flex>
                        </Box>
                    </Show>
                </VStack>
            </Box>
        </Box>
    )

    return isDesktop ? (
        sidebarContent()
    ) : (
        <CustomFullScreenModal
            body={sidebarContent()}
            isHeaderVisible={false}
            isFooterVisible={false}
            isOpen={isOpened}
            onClose={() => closeModal()}
            isFullScreen={true}
            toBottom
        />
    )
}

export default Sidebar
